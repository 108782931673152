import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import { listen, prefetch } from "quicklink";

export default (el, props) => {
    const NEXT_TIME = 6;
    
    const $el = $(el);
    const $inner = $el.find('[data-inner-wrapper]');
    const $link = $el.find('a');
    const { autoPlay } = props;
    
    const $progressBar = $el.find('[data-progress-bar]'); 

    let observer = null;
    let isTiming = false;
    let isTransitioning = false;
    let timer = null;
    let startTime = 0;
    let nextUrl = '';

    const init = () => {
        nextUrl = $link.attr('href');

        $link.on('click', e => {
            e.preventDefault();
            
            if (!isTransitioning) {
                transition();
            }
        });

        if (nextUrl !== '') {
            
            listen({
                el: $el.get(0),
                timeout: 500
            })
            
            observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    const { isIntersecting, intersectionRatio } = entry;
                    onIntersect(!(!isIntersecting || intersectionRatio < 0.8));
                });
            }, {
                threshold: [0.8]
            });

            observer.observe($el.get(0));
        }
    };

    const destroy = () => {
        killObserver();
        
        if (observer) {
            observer.disconnect();
        }
        if (timer) {
            cancelAnimationFrame(timer);
        }
    };

    const onIntersect = (isIntersecting) => {
        if (!isTransitioning && autoPlay) {
            if (isIntersecting && !isTiming) {
                startTiming();
            } else if (!isIntersecting && isTiming) {
                stopTiming();
            }
        }
    };

    const killObserver = () => {
        if (observer) {
            observer.unobserve($el.get(0));
            observer = null;
        }
    };
    
    const startTiming = () => {
        if (autoPlay) {
            cancelAnimationFrame(timer);
            startTime = (new Date()).getTime();
            timer = requestAnimationFrame(timerProgress);
            isTiming = true;
        }
    };
    
    const stopTiming = () => {
        cancelAnimationFrame(timer);
        gsap.to($progressBar.get(0), { duration: 0.6, width: 0, ease: 'quint.inOut' });
        isTiming = false;
    };
    
    const timerProgress = () => {
        const progress = Math.min(1, ((((new Date()).getTime() - startTime)/1000)/NEXT_TIME));
        gsap.set($progressBar.get(0), { width: (progress * 100) + '%' });
        
        if (progress === 1) {
            transition();
        } else {
            timer = requestAnimationFrame(timerProgress);
        }
    };
    
    const transition = () => {
        isTransitioning = true;
        cancelAnimationFrame(timer);
        $el.height($inner.height());
        
        const $bottomOverlay = $el.find('[data-bottom-overlay]');
        const $arrow = $el.find('[data-arrow]');
        const $barWrapper = $el.find('[data-bar-wrapper]');
        
        $inner.removeClass('pt-60', 'm:pt-80', 'l:pt-100', 'relative').addClass('top-padding', 'fixed', 'z-850');
        $inner.css({ top: $el.offset().top - Viewport.scrollTop });
        
        gsap.to([$arrow.get(0), $barWrapper.get(0)], { opacity: 0, duration: 0.5, ease: 'sine.out' });
        gsap.to($inner.get(0), { top: 0, height: Viewport.height*0.85, duration: 0.8, ease: 'quint.inOut' });
        gsap.to($bottomOverlay.get(0), { height: Viewport.height*0.15, duration: 0.8, ease: 'quint.inOut', onComplete: () => {
                window.scrollTo(0, 0);
                
                setTimeout(() => {
                    navigate();
                }, 300);
        } });
    };
    
    const navigate = () => {
        window.vrsg.setCookie('lastTransition', 'loop');
        window.location.href = nextUrl;
    }
    
    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import { MENU_TOGGLE, MENU_OPEN, MENU_CLOSE } from '../lib/events';

export default (el, props) => {
    const MENU_TWO_COLUMN_BP = 600;

    const $body = $('body');
    const $el = $(el);
    const $wrapper = $el.find('[data-menus-wrapper]');
    const $mainMenu = $el.find('[data-main-menu]');
    const $caseMenu = $el.find('[data-case-menu]');
    const $mainMenuLine = $mainMenu.find('[data-line]');
    const $caseMenuLine = $caseMenu.find('[data-line]');
    const $mainMenuItems = $mainMenu.find('[data-menu-item]');
    const $caseMenuItems = $caseMenu.find('[data-menu-item]');
    const $caseMenuLabel = $caseMenu.find('[data-highlight-label]');

    let isOpen = false;

    const init = () => {
        Dispatch.on(MENU_TOGGLE, onMenuToggle);
        Viewport.on('resize', onResize);
        $('body').on('keyup', onBodyKeyUp);
    };

    const destroy = () => {
        Dispatch.off(MENU_TOGGLE, onMenuToggle);
        Viewport.off('resize', onResize);
        $('body').off('keyup', onBodyKeyUp);
    };

    const onMenuToggle = (key, data) => {
        const immediate = ((data !== undefined) && (data.immediate !== undefined)) ? data.immediate : false;
        
        if (!isOpen) {
            open(immediate);
        } else {
            close(immediate);
        }
    };
    
    const onResize = e => {
        if (Viewport.breakpoint.size < MENU_TWO_COLUMN_BP) {
            gsap.set($mainMenuLine.get(0), { height: Viewport.height - 120 });
        } else {
            gsap.set($mainMenuLine.get(0), { height: '' });
        }
    };
    
    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            close();
        }
    };
    
    const open = immediate => {
        $body.addClass('is-menu-open');
        
        gsap.set($el.get(0), { display: 'block', height: 0, overflow: 'hidden' });
        
        if (Viewport.breakpoint.size < MENU_TWO_COLUMN_BP) {
            gsap.set($mainMenuLine.get(0), { height: $wrapper.height() - 120 });
        }
        
        gsap.set([$mainMenuLine.get(0), $caseMenuLine.get(0)], { y: Viewport.height, opacity: 1 });
        gsap.set($caseMenuLabel.get(0), { opacity: 0, y: -30 });
        gsap.set($mainMenuItems.nodes, { opacity: 0, y: 30 });
        gsap.set($caseMenuItems.nodes, { opacity: 0, y: 20 });

        gsap.to($el.get(0), { duration: !immediate ? 0.3 : 0, height: '100%', ease: 'sine.out' });
        gsap.to($mainMenuLine.get(0), { duration: !immediate ? 0.6 : 0, delay: !immediate ? 0.2 : 0, y: 0, ease: 'expo.out' });
        gsap.to($caseMenuLine.get(0), { duration: !immediate ? 0.6 : 0, delay: !immediate ? 0.5 : 0, y: 0, ease: 'expo.out' });
        gsap.to($caseMenuLabel.get(0), { duration: !immediate ? 0.5 : 0, delay: !immediate ? 0.8 : 0, y: 0, opacity: 1, ease: 'quint.out' });

        gsap.to($mainMenuItems.nodes, { duration: !immediate ? 0.8 : 0, delay: !immediate ? 0.6 : 0, y: 0, opacity: 1, stagger: "-0.025", ease: 'quint.out' });
        gsap.to($caseMenuItems.nodes, { duration: !immediate ? 0.8 : 0, delay: !immediate ? 0.7 : 0, y: 0, opacity: 1, stagger: "-0.025", ease: 'quint.out', onComplete: () => {
                gsap.set($el.get(0), { overflow: 'auto' });
            }
        });

        Dispatch.emit(MENU_OPEN);
        isOpen = true;
    };

    const close = immediate => {
        $body.removeClass('is-menu-open');
        gsap.set($el.get(0), { overflow: 'hidden' });

        gsap.to([$mainMenuItems.nodes, $caseMenuItems.nodes, $caseMenuLabel.get(0), $mainMenuLine.get(0), $caseMenuLine.get(0)], { duration: !immediate ? 0.1 : 0, opacity: 0 });
        gsap.to($el.get(0), {
            duration: !immediate ? 0.3 : 0, delay: !immediate ? 0.2 : 0, height: '0%', ease: 'sine.out', onComplete: () => {
                gsap.set($el.get(0), { display: 'none' });
            }
        });

        Dispatch.emit(MENU_CLOSE);
        isOpen = false;
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import gsap from 'gsap';


export default (el, props) => {
    const $el = $(el);

    const init = () => {
        $el.find('button').each(el => {
            $(el).data('fontFeatures', { wght: 300, ital: 0 });
        });
        
        $el.on('click', 'button', e => {
            const $button = $(e.triggerTarget);
            const $content = $button.parent().next();
            const expanded = $button.attr('aria-expanded') === 'true' || false

            $button.attr('aria-expanded', !expanded);

            if (expanded) {
                gsap.to($content.get(0), {
                    duration: 0.6, height: 0, ease: 'quint.inOut', onComplete: () => {
                        $content.attr('hidden', '');
                    }
                });
                gsap.to($button.data('fontFeatures'), { wght: 300, ital: 0, duration: 1, ease: 'power2.inOut', onUpdate: updateVals, onUpdateParams: [$button] })
            } else {
                $content.attr('hidden', null);

                gsap.set($content.get(0), { height: 0, overflow: 'hidden' });
                gsap.to($content.get(0), { duration: 0.6, height: 'auto', ease: 'quint.inOut' });
                gsap.to($button.data('fontFeatures'), { wght: 700, ital: 10, duration: 1, ease: 'power2.inOut', onUpdate: updateVals, onUpdateParams: [$button] })
            }
        });
    };
    
    const destroy = () => {
        $el.off('click');
    };
    
    const updateVals = $el => {
        $el.get(0).style.setProperty('--ttVariationBoth', '"wght" ' + $el.data('fontFeatures').wght + ',"ital" ' + $el.data('fontFeatures').ital);
        $el.get(0).style.setProperty('--ttVariationWeight', '"wght" ' + $el.data('fontFeatures').wght);
        $el.get(0).style.setProperty('--ttVariationItalics', '"ital" ' + $el.data('fontFeatures').ital);
    };
    

    return {
        init,
        destroy
    };
};

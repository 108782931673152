import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';

import { STOP_ACTIVE_SOUND_PLAYERS } from '../lib/events';

export default el => {
    const $el = $(el);

    const audio = $el.find('[data-audio]').get(0);
    const $btnPlay = $el.find('[data-play-toggle]');
    const $line = $el.find('[data-line]');
    const $text = $el.find('[data-text]');

    const $stickyPlayer = $el.find('[data-sticky-player]');

    const $iconSound = $el.find('[data-icon-sound]');
    const $iconPause = $el.find('[data-icon-pause]');
    const $timecode = $el.find('[data-time-code]');

    let playedPercent = 0;
    let firstTimePlay = true;
    let updateId = 0;
    let isPlaying = false;
    let stickyShown = false;
    let duration = 0;
    let observer = null;

    const update = () => {
        playedPercent = audio.currentTime / audio.duration;
        //gsap.to(progress, {duration: 0.1, scaleX: playedPercent, ease: 'none'});
        updateTimeCode(playedPercent);
    }

    const startUpdateInterval = () => {
        stopUpdateInterval();
        updateId = setInterval(update, 75);
    }

    const stopUpdateInterval = () => {
        clearInterval(updateId);
    }

    const play = () => {
        Dispatch.emit(STOP_ACTIVE_SOUND_PLAYERS);
        audio.play();
        isPlaying = true;
    }

    const onPlay = () => {
        $el.addClass('is-playing');
        $iconSound.css({ display: 'none' });
        $iconPause.css({ display: 'block' });
        startUpdateInterval();
    }

    const pause = () => {
        audio.pause();
        isPlaying = false;
    }

    const onPause = () => {
        $el.removeClass('is-playing');
        $iconSound.css({ display: 'block' });
        $iconPause.css({ display: 'none' });
        stopUpdateInterval();
        hideSticky();
    }

    const onComplete = () => {
        stopUpdateInterval();
        playedPercent = 0;
        isPlaying = false;
        updateTimeCode(0);
    }

    const toggle = () => {
        if (isPlaying) {
            pause();
        } else {
            play();
        }
    };

    const onMetaData = () => {
        duration = audio.duration;
        updateTimeCode(0);
    };

    const onStopActivePlayers = () => {
        pause();
    };

    const onIntersect = (isIntersecting) => {
        if (isIntersecting) {
            hideSticky();
        } else if (!isIntersecting && isPlaying) {
            showSticky();
        }
    };

    const updateTimeCode = (progress) => {
        const current = Math.floor((1 - progress) * duration);
        const mins = Math.floor(current / 60);
        const secs = Math.floor(current % 60);

        $timecode.text(mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0'));
    };

    const showSticky = () => {
        if (!stickyShown) {
            gsap.set($stickyPlayer.get(0), { display: 'block', bottom: -50 });
            gsap.to($stickyPlayer.get(0), { duration: 0.7, bottom: 30, ease: 'quint.out' });

            updateTimecodeWidths();

            stickyShown = true;
        }
    };

    const hideSticky = () => {
        if (stickyShown) {
            gsap.to($stickyPlayer.get(0), {
                duration: 0.2, bottom: -50, ease: 'sine.in', onComplete: () => {
                    gsap.set($stickyPlayer.get(0), { display: 'none' });
                }
            });

            stickyShown = false;
        }
    };

    const updateTimecodeWidths = () => {
        $timecode.each(node => {
            const $node = $(node);
            $node.css({ width: '' })
            $node.text('00:00');
            $node.css({ width: $node.width(), display: 'inline-block' })
        });

        updateTimeCode(0);
    };
    
    const animateIn = () => {
        gsap.to($line.get(0), {
            scaleY: 1,
            duration: 0.6,
            ease: 'quint.out'
        });

        gsap.to($btnPlay.get(0), {
            scale: 1,
            opacity: 1,
            duration: 0.7,
            delay: 0.5,
            ease: 'quint.out'
        });

        gsap.to([$timecode.get(0), $text.get(0)], {
            duration: 0.6,
            delay: 0.7,
            opacity: 1,
            y: 0,
            ease: 'quint.out',
            stagger: 0.1
        });

    };

    const init = () => {
        $btnPlay.on('click', toggle);

        $(audio).on('playing', onPlay);
        $(audio).on('pause', onPause);
        $(audio).on('ended', onComplete);
        $(audio).on('loadedmetadata', onMetaData);

        $(audio).attr('src', $(audio).data('audio'));

        updateTimecodeWidths();

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                onIntersect(isIntersecting);
            });
        }, {
            threshold: [0]
        });

        if (shouldAnimate()) {
            gsap.set($line.get(0), {
                transformOrigin: 'top',
                scaleY: 0
            });

            gsap.set($btnPlay.get(0), {
                scale: 0
            });

            gsap.set([$text.get(0), $timecode.get(0)], {
                opacity: 0,
                y: 20
            });

            Animator.register({
                type: 'custom',
                callback: animateIn,
                element: el,
                offsetLength: 0.15
            });
        }

        Dispatch.on(STOP_ACTIVE_SOUND_PLAYERS, onStopActivePlayers);

        observer.observe($el.get(0));
    };

    const destroy = () => {
        $btnPlay.off('click');

        $(audio).off('playing');
        $(audio).off('pause');
        $(audio).off('ended');
    };

    return {
        init,
        destroy
    };

};

import $ from '../core/Dom';
import Splitting from "splitting";
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    let timer = null;

    const init = () => {


        if (shouldAnimate()) {
            setTimeout(() => {
                doSplit();
                resetAnimation();
                $el.css({ opacity: 1 });
            }, 10);

        }
    };

    const destroy = () => {

    };

    const resetAnimation = () => {
        gsap.set($el.find('.word').nodes, { opacity: 0 });

        Animator.register({
            type: 'custom',
            callback: animateIn,
            element: el,
            offsetLength: 0.3
        });

    };

    const doSplit = () => {
        const results = Splitting({ target: el, by: 'words' });
    };

    const animateIn = () => {
        gsap.to($el.find('.word').nodes, {
            opacity: 1, duration: 1.2, ease: 'sine.out', stagger: 0.05
        });
    };

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $images = $el.find('[data-image-item]');
    const $wrapper = $el.find('[data-gallery-wrapper]');
    
    let contentSize = 0;
    let scrolledPos = 0;
    let observer = null;

    const init = () => {
        if ($images.length > 0) {
            updateContentSize();
            
            observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    const { isIntersecting, intersectionRatio } = entry;
                    
                    if (isIntersecting) {
                        onIntersect();
                    }
                });
            }, {
                threshold: [0]
            });
    
            observer.observe($el.get(0));

            Viewport.on('resize', onResize);
            Viewport.on('scroll', onScroll);
        }
    };

    const destroy = () => {
        if ($images.length > 0) {
            observer.disconnect();
            Viewport.off('resize', onResize);
            Viewport.off('scroll', onScroll);
        }
    };
    
    const onIntersect = () => {
        $el.find('img.lazyload').addClass('lazypreload');
        observer.disconnect();
    };

    const onScroll = e => {
        let p = 0;
        
        if (Viewport.scrollTop < $el.offset().top) {
            p = 0;
        } else if (Viewport.scrollTop > $el.offset().top + $el.height() - Viewport.height) {
            p = 1
        } else {
            p = (Viewport.scrollTop - $el.offset().top) / ($el.height() - Viewport.height);
        }
        
        const newPos = Math.round((contentSize - $wrapper.width() - 20) * p);
        
        if (newPos !== scrolledPos) {
            gsap.to($wrapper.get(0), { duration: 0.3, x: -newPos, ease: 'expo.out' });
            scrolledPos = newPos;
        }
    };

    const onResize = e => {
        updateContentSize();
    };
    
    const updateContentSize = () => {
        const $lastItem = $($images.nodes.slice(-1));
        contentSize = $lastItem.position().left + $lastItem.width();
        $el.height(contentSize);
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';


export default el => {
    const $el = $(el);

    const $quoteText = $el.find('[data-quote-text]');
    const $sourceText = $el.find('[data-source-text]');
    const $line = $el.find('[data-line]');
    
    const animateIn = () => {
        gsap.to($line.get(0), {
            scaleY: 1,
            duration: 0.9,
            ease: 'quint.out'
        });

        gsap.to($sourceText.get(0), {
            duration: 0.7,
            delay: 0.1,
            y: 0,
            ease: 'quint.out'
        });

        gsap.to($sourceText.get(0), {
            duration: 0.7,
            delay: 0.1,
            opacity: 1,
            ease: 'linear'
        });

        gsap.to($quoteText.get(0), {
            duration: 0.8,
            delay: 0.8,
            opacity: 1,
            ease: 'linear'
        });

    };

    const init = () => {
        if (shouldAnimate()) {
            gsap.set($line.get(0), {
                transformOrigin: 'top',
                scaleY: 0
            });

            gsap.set($sourceText.get(0), {
                opacity: 0,
                y: -30
            });

            gsap.set($quoteText.get(0), {
                opacity: 0
            });

            Animator.register({
                type: 'custom',
                callback: animateIn,
                element: el,
                offsetLength: 0.3
            });
        }
    };

    const destroy = () => {
    };

    return {
        init,
        destroy
    };

};

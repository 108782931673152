import $ from '../core/Dom';
import Animator from '../lib/Animator';
import shouldAnimate from '../lib/ReducedMotion';

import gsap from 'gsap';


export default el => {
    const $el = $(el);

    const $linkText = $el.find('[data-link-text]');
    const $line = $el.find('[data-line]');
    const $icon = $el.find('[data-icon]');
    
    const animateIn = () => {
        gsap.to($line.get(0), {
            scaleY: 1,
            duration: 0.9,
            ease: 'quint.out'
        });

        gsap.to($icon.get(0), {
            scale: 1,
            opacity: 1,
            duration: 0.7,
            delay: 0.5,
            ease: 'quint.out'
        });

        gsap.to($linkText.get(0), {
            duration: 1,
            delay: 0.7,
            opacity: 1,
            y: 0,
            ease: 'quint.out',
            stagger: 0.1
        });

    };

    const init = () => {
        if (shouldAnimate()) {
            gsap.set($line.get(0), {
                transformOrigin: 'top',
                scaleY: 0
            });

            gsap.set($icon.get(0), {
                scale: 0
            });

            gsap.set($linkText.get(0), {
                opacity: 0,
                y: 30
            });

            Animator.register({
                type: 'custom',
                callback: animateIn,
                element: el,
                offsetLength: 0.3
            });
        }
    };

    const destroy = () => {
    };

    return {
        init,
        destroy
    };

};

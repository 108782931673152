import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    let timer = null;
    
    const init = () => {
        startAnimation();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        stopAnimation();
        Viewport.off('resize', onResize);
    };

    const onResize = e => {
        
    };

    const startAnimation = () => {
        cancelAnimationFrame(timer);
        timer = requestAnimationFrame(buttonPosition);
    };
    
    const stopAnimation = () => {
        cancelAnimationFrame(timer);
    };
    
    const buttonPosition = () => {
        const position = Viewport.scrollTop / 4;
        gsap.set($el.get(0), { y: position });
        timer = requestAnimationFrame(buttonPosition);
    };


    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import gsap from 'gsap';
import Splitting from 'splitting';


export default (el, props) => {
    const $el = $(el);

    let originalContent = null;

    const vals = {
        wght: 300,
        ital: 0
    }

    const init = () => {
        originalContent = $('<span>' + $el.html() + '</span>');

        setTimeout(() => {
            doSplit();
        }, 10);

        updateVals();
        gsap.to(vals, { wght: 700, ital: 10, duration: 1.1, delay: 1, ease: 'power2.inOut', onUpdate: updateVals })

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    const doSplit = () => {
        $el.empty();
        $el.removeClass('whitespace-nowrap', 'words', 'lines', 'splitting');
        $el.append(originalContent.get(0).cloneNode(true));
        
        const results = Splitting({ target: el, by: 'lines', force: true });

        $el.find('br').each(br => {
            br.remove();
        });
        
        results.forEach(result => {
            result.lines.forEach(line => {
                const lastItem = line[line.length - 1];
                $(lastItem).append('<br>');
            })
        });

        $el.addClass('whitespace-nowrap');
    };

    const updateVals = () => {
        el.style.setProperty('--ttVariationBoth', '"wght" ' + vals.wght + ',"ital" ' + vals.ital);
        el.style.setProperty('--ttVariationWeight', '"wght" ' + vals.wght);
        el.style.setProperty('--ttVariationItalics', '"ital" ' + vals.ital);
    };

    const onResize = e => {
        doSplit();
    };


    return {
        init,
        destroy
    };
};

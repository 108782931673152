import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import { MENU_TOGGLE, MENU_OPEN, MENU_CLOSE } from '../lib/events';

export default (el, props) => {
    const HEADER_COLLAPSE_THRESHOLD = 80;
    const TOGGLE_EXPANDED_BP = 750;
    const MENU_TWO_COLUMN_BP = 600;

    const $el = $(el);
    const $logo = $el.find('[data-logo]');

    const $menuToggle = $el.find('[data-menu-toggle]');
    const $toggleMidBar = $el.find('[data-menu-toggle-mid-bar]');
    const $toggleText = $el.find('[data-menu-toggle-text]');
    const $toggleTextOpen = $el.find('[data-menu-toggle-text-open]');
    const $toggleTextClose = $el.find('[data-menu-toggle-text-close]');

    let isLogoVisible = true;
    let isMenuOpen = false;
    let focusedElement = null;

    let toggleStatus = Viewport.breakpoint.size >= TOGGLE_EXPANDED_BP ? 'open' : 'collapsed';

    const init = () => {
        const navId = $menuToggle.attr('href');
        
        Dispatch.on(MENU_OPEN, onMenuOpen);
        Dispatch.on(MENU_CLOSE, onMenuClose);

        $menuToggle
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    Dispatch.emit(MENU_TOGGLE);
                }
            })
            .on('click', e => {
                e.preventDefault();
                Dispatch.emit(MENU_TOGGLE);
            })
            .get(0)
            .removeAttribute('href');
        
        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                window.location.hash = '';
                Dispatch.emit(MENU_TOGGLE, { immediate: true });
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });

        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        $el.off('click');
        Viewport.off('scroll', onScroll);
    };

    const onScroll = () => {
        if (!isMenuOpen) {
            if (isLogoVisible && Viewport.scrollTop > HEADER_COLLAPSE_THRESHOLD) {
                hideLogo();
                changeToggle('collapsed');
            } else if (!isLogoVisible && Viewport.scrollTop < HEADER_COLLAPSE_THRESHOLD) {
                showLogo();

                if (Viewport.breakpoint.size < TOGGLE_EXPANDED_BP) {
                    changeToggle('collapsed');
                } else {
                    changeToggle('open');
                }
            }
        }
    };

    const onMenuOpen = () => {
        if (!isLogoVisible) {
            showLogo()
        }

        changeToggle('close');

        $menuToggle.get(0).setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        Viewport.lockTabbing(el, $menuToggle.get(0));

        isMenuOpen = true;
    };

    const onMenuClose = () => {
        isMenuOpen = false;
        if (Viewport.scrollTop > HEADER_COLLAPSE_THRESHOLD || Viewport.breakpoint.size < TOGGLE_EXPANDED_BP) {
            changeToggle('collapsed');
        } else {
            changeToggle('open');
        }

        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $menuToggle.get(0).setAttribute('aria-expanded', 'false');
        
        onScroll();
    };

    const hideLogo = () => {
        gsap.to($logo.get(0), { duration: 0.3, y: -$logo.height() - $logo.offset().top, ease: 'sine.in' });

        isLogoVisible = false;
    };

    const showLogo = () => {
        gsap.to($logo.get(0), { duration: 0.6, y: 0, ease: 'quint.out' });

        isLogoVisible = true;
    };

    const changeToggle = status => {
        if (status === toggleStatus) {
            return;
        }

        if (status === 'collapsed') {
            $toggleMidBar.css({ display: 'block', height: '' });
            const barHeight = $toggleMidBar.height();
            $toggleMidBar.css({ height: '0' });

            gsap.to([$toggleTextOpen.get(0), $toggleTextClose.get(0)], { duration: 0.1, opacity: 0 });
            gsap.to($toggleText.get(0), { duration: 0.1, opacity: 0 });
            gsap.to($toggleText.get(0), { duration: 0.4, delay: 0.1, width: 0, ease: 'quint.out' });
            gsap.to($toggleMidBar.get(0), { duration: 0.4, delay: 0.2, height: barHeight, ease: 'quint.out' });

        } else if (status === 'open') {

            if (toggleStatus === 'close') {
                gsap.set($toggleText.get(0), { width: $toggleText.width() });

                gsap.to($toggleTextClose.get(0), {
                    duration: 0.1, opacity: 0, onComplete: () => {
                        $toggleTextOpen.css({ display: '' });
                        $toggleTextClose.css({ display: '' });

                        gsap.to($toggleText.get(0), { duration: 0.4, delay: 0.1, width: 'auto', ease: 'quint.out' });
                        gsap.to($toggleText.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                        gsap.to($toggleMidBar.get(0), { duration: 0.1, height: 0, ease: 'sine.in' });
                        gsap.to($toggleTextOpen.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                    }
                });
            } else {
                $toggleTextOpen.css({ display: 'block' });
                $toggleTextClose.css({ display: 'none' });

                gsap.to([$toggleTextOpen.get(0), $toggleTextClose.get(0)], { duration: 0.1, opacity: 1 });
                gsap.to($toggleText.get(0), { duration: 0.4, delay: 0.1, width: 'auto', ease: 'quint.out' });
                gsap.to($toggleText.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                gsap.to($toggleMidBar.get(0), { duration: 0.1, height: 0, ease: 'sine.in' });
            }


        } else if (status === 'close') {
            gsap.to($toggleMidBar.get(0), { duration: 0.1, height: 0, ease: 'sine.in' });

            if (toggleStatus === 'open') {
                gsap.set($toggleText.get(0), { width: $toggleText.width() });

                gsap.to($toggleTextOpen.get(0), {
                    duration: 0.1, opacity: 0, onComplete: () => {
                        $toggleTextOpen.css({ display: 'none' });
                        $toggleTextClose.css({ display: 'block', opacity: 0 });

                        gsap.to($toggleText.get(0), { duration: 0.4, delay: 0.1, width: 'auto', ease: 'quint.out' });
                        gsap.to($toggleText.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                        gsap.to($toggleTextClose.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                    }
                });
            } else {
                $toggleTextOpen.css({ display: 'none' });
                $toggleTextClose.css({ display: 'block', opacity: 0 });

                gsap.to($toggleText.get(0), { duration: 0.4, delay: 0.1, width: 'auto', ease: 'quint.out' });
                gsap.to($toggleText.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                gsap.to($toggleTextClose.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
            }

        }

        toggleStatus = status;
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $outerWrapper = $el.find('[data-outer-wrapper]');
    const $textWrapper = $el.find('[data-text-wrapper]');
    const $images = $el.find('[data-image-wrapper]');
    const $colorBox = $el.find('[data-color-box]');

    let isOpen = false;
    let observer = null;

    const init = () => {
        updateContentSize();

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                onIntersect(!(!isIntersecting || intersectionRatio < 0.45));
            });
        }, {
            threshold: [0.45]
        });

        observer.observe($outerWrapper.get(0));

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        killObserver();
        observer.disconnect();
        Viewport.off('resize', onResize);
    };

    const onResize = e => {
        updateContentSize();

        if (isOpen) {
            const offset = getImageOffset();
            gsap.set($images.get(0), { x: -offset });
            gsap.set($images.get(1), { x: offset });
            
            if ($colorBox.length > 0) {
                const colorBoxOffset = getColorBoxOffset();
                gsap.set($colorBox.get(0), { x: offset + colorBoxOffset, y: -colorBoxOffset });
            }
            
        }
    };

    const updateContentSize = () => {
        const textHeight = $textWrapper.height();
        $outerWrapper.css({ minHeight: textHeight });
    };

    const onIntersect = (isIntersecting) => {
        if (isIntersecting && !isOpen) {
            open();
        } else if (!isIntersecting && isOpen) {
            close();
        }
    };

    const killObserver = () => {
        if (observer) {
            observer.unobserve($el.get(0));
            observer = null;
        }
    };

    const getImageOffset = () => {
        return $($images.get(0)).width() + 20 - $textWrapper.position().left - parseInt(window.getComputedStyle($textWrapper.get(0), null).getPropertyValue('padding-left'))
    };
    
    const getColorBoxOffset = () => {
        return parseInt(window.getComputedStyle($colorBox.get(0), null).getPropertyValue('right')) - 20;
    };

    const open = () => {
        const offset = getImageOffset();

        gsap.set($textWrapper.get(0), { scale: 0.9 });

        gsap.to($images.get(0), { x: -offset, delay: 0.2, duration: 1, ease: 'quart.inOut' });
        gsap.to($images.get(1), { x: offset, delay: 0.2, duration: 1, ease: 'quart.inOut' });
        gsap.to($textWrapper.get(0), { scale: 1, delay: 0.8, duration: 1.2, ease: 'quint.out' });
        gsap.to($textWrapper.get(0), { opacity: 1, delay: 0.8, duration: 1.2, ease: 'none' });

        if ($colorBox.length > 0) {
            const colorBoxOffset = getColorBoxOffset();
            gsap.to($colorBox.get(0), { x: offset + colorBoxOffset, y: -colorBoxOffset, delay: 0.2, duration: 1.2, ease: 'quart.inOut' });
        }

        isOpen = true;
    };

    const close = () => {
        gsap.to($textWrapper.get(0), { scale: 0.9, duration: 0.6, ease: 'sine.in' });
        gsap.to($textWrapper.get(0), { opacity: 0, duration: 0.6, ease: 'none' });
        gsap.to($images.nodes, { x: 0, duration: 1, ease: 'quart.inOut' });

        if ($colorBox.length > 0) {
            gsap.to($colorBox.nodes, { x: 0, y: 0, duration: 1, ease: 'quart.inOut' });
        }

        isOpen = false;
    };

    return {
        init,
        destroy
    };
};

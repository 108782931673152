import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import request from '../core/request';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import { FORCE_VIDEO_RESIZE } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const { loadUrl } = props;

    const $overlay = $el.find('[data-project-overlay]');
    const $overlayInner = $el.find('[data-project-overlay-inner]');

    let isOpen = false;

    let $slideWrapper = null;
    let $closeWrapper = null;
    let $slides = null;
    let currentIndex = 0;
    let wrapperOriginalWidth;
    let wrapperOriginalHeight;
    let focusedElement = null;
    let openedByHash = false;


    const init = () => {
        $el.on('click', '[data-project-button]', e => {
            const $trigger = $(e.triggerTarget);
            const uid = $trigger.data('project-uid');
            const slug = $trigger.data('project-slug');
            open(uid, slug, $trigger);
        });

        $el.on('click', '[data-close-btn]', e => {
            if (window.history) {
                window.history.back();
            } else {
                close();
            }
        });

        $el.on('click', '[data-next-button]', e => {
            nextSlide();
        });

        $el.on('click', '[data-prev-button]', e => {
            prevSlide();
        });

        Viewport.on('resize', onResize);

        if (screen.orientation) {
            screen.orientation.addEventListener("change", onResize);
        } else {
            window.addEventListener("orientationchange", onResize, false);
        }

        $('body').on('keyup', onBodyKeyUp);

        if (window.history) {
            window.addEventListener('popstate', (event) => {
                const state = event.state;

                if (state && state.slug && !isOpen) {
                    openBySlug(state.slug);
                } else {
                    close();
                }
            });
        }

        const url = new URL(window.location);

        if (url.hash !== '' && url.hash !== '#') {
            openedByHash = true;
            openBySlug(url.hash.substring(1));
        }

        if ($overlayInner.find('[data-slide-wrapper]').length > 0) {
            initSlider();
        }
    };

    const destroy = () => {
        $el.off('click');
        $('body').off('keyup', onBodyKeyUp);
        Viewport.off('resize', onResize);
    };

    const onResize = () => {
        if (isOpen && $slideWrapper) {
            const currentWidth = $slideWrapper.width();
            const currentHeight = $slideWrapper.height();

            $slideWrapper.css({ width: '', height: '' });

            wrapperOriginalWidth = $slideWrapper.width();
            wrapperOriginalHeight = $slideWrapper.height();

            $slideWrapper.css({ width: currentWidth, height: currentHeight });
            showSlide(currentIndex);
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;

        if (['Escape', 27].indexOf(key) > -1) {
            close();
        }

        if ($slides && $slides.length > 1) {
            if (['ArrowLeft', 37].indexOf(key) > -1) {
                prevSlide();
            }
            if (['ArrowRight', 39].indexOf(key) > -1) {
                nextSlide();
            }
        }
    };

    const openBySlug = (slug) => {
        const $elem = $el.find('button[data-project-slug="' + slug + '"]');
        
        if ($elem.length > 0) {
            $elem.get(0).click();
        }
    }

    const open = (uid, slug, $trigger) => {
        isOpen = true;
        $overlay.removeClass('hidden').addClass('flex');
        $overlayInner.empty();
        
        if (openedByHash) {
            history.pushState({ }, '', '#');
            openedByHash = false;
        }

        gsap.set([$overlay.get(0), $overlayInner.get(0)], { opacity: 0 });

        gsap.to($overlay.get(0), {
            opacity: 1, duration: 0.2, onComplete: () => {
                $('body').css('overflow', 'hidden');

                request
                    .get(loadUrl)
                    .query({ uid })
                    .then(res => {
                        if (res.statusCode === 200) {
                            parseData(res.text);

                            $trigger.get(0).setAttribute('aria-expanded', 'true');
                            focusedElement = document.activeElement || null;
                            Viewport.initTabbing();
                            Viewport.lockTabbing($overlayInner.get(0), $overlayInner.find('[data-close-btn]').get(0));
                        } else {
                            // todo : error
                        }
                    });
            }
        });

        const url = new URL(window.location);

        if (window.history && url.hash !== '#' + slug) {
            history.pushState({ slug }, '', '#' + slug);
        }
    };

    const parseData = data => {
        $overlayInner.append($(data));
        gsap.to($overlayInner.get(0), { opacity: 1, delay: 0.4, duration: 0.3 });
        initSlider();
    };

    const close = () => {
        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $el.find('[data-project-button]').attr('aria-expanded', 'false');
        $overlay.addClass('hidden').removeClass('flex');
        $('body').css('overflow', '');
        isOpen = false;
    };

    const initSlider = () => {
        $slideWrapper = $overlayInner.find('[data-slide-wrapper]')
        $closeWrapper = $overlayInner.find('[data-close-wrapper]')
        $slides = $overlayInner.find('[data-slide]')

        Components.init($slideWrapper);

        onResize();
        changeSlide(null, currentIndex);
    };

    const changeSlide = (oldIndex, newIndex) => {
        if (oldIndex !== null) {
            hideSlide(oldIndex);
        }
        showSlide(newIndex);
    };

    const hideSlide = index => {
        const $slide = $slides.eq(index);
        gsap.killTweensOf($slide.get(0));
        gsap.set($slide.get(0), { opacity: 0 });
    };

    const showSlide = index => {
        const $slide = $slides.eq(index);
        const ratio = $slide.data('aspect-ratio')
        let newWidth, newHeight;

        if (ratio > wrapperOriginalWidth / wrapperOriginalHeight) { // use width
            newWidth = wrapperOriginalWidth;
            newHeight = wrapperOriginalWidth * (1 / ratio);
        } else { // use height
            newWidth = wrapperOriginalHeight * ratio;
            newHeight = wrapperOriginalHeight;
        }

        gsap.killTweensOf($slideWrapper.get(0));
        gsap.to($slideWrapper.get(0), {
            duration: 0.2, width: newWidth, height: newHeight, ease: 'power1.out', onComplete: () => {
                Dispatch.emit(FORCE_VIDEO_RESIZE);
                gsap.to($slide.get(0), { duration: 0.7, opacity: 1, ease: 'sine.out' });
            }
        });

        currentIndex = index;
    };

    const nextSlide = () => {
        const oldIndex = currentIndex;
        let newIndex = currentIndex + 1;

        if (newIndex >= $slides.length) {
            newIndex = 0;
        }

        changeSlide(oldIndex, newIndex);
    };

    const prevSlide = () => {
        const oldIndex = currentIndex;
        let newIndex = currentIndex - 1;

        if (newIndex < 0) {
            newIndex = $slides.length - 1;
        }

        changeSlide(oldIndex, newIndex);
    };

    return {
        init,
        destroy
    };
};

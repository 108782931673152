import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    const $buttonWrap = $('[data-button-wrap]');
    const $button = $('[data-button]');

    let originalOffset = 0;
    let buttonSize = 0;
    let isFixed = false;

    const init = () => {
        onResize();
        onScroll();
        
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    const onResize = e => {
        originalOffset = $el.offset().top;
        buttonSize = $button.height();
    };

    const onScroll = e => {
        if (!isFixed && Viewport.scrollTop > originalOffset + buttonSize*2) {
            gsap.set($buttonWrap.get(0), { position: 'fixed', top: Viewport.height + buttonSize });
            gsap.to($buttonWrap.get(0), { duration: 0.8, top: getFixedPos(), ease: 'quint.out' });
            
            isFixed = true;
        }

        if (isFixed && Viewport.scrollTop < originalOffset + buttonSize*2) {
            gsap.to($buttonWrap.get(0), {
                duration: 0.4, top: Viewport.height + buttonSize, ease: 'sine.out', onComplete: () => {
                    gsap.set($buttonWrap.get(0), { position: '', top: '', opacity: 0 });
                    gsap.to($buttonWrap.get(0), { duration: 0.1, opacity: 1 });
                }
            });

            isFixed = false;
        }
    };

    const getFixedPos = () => {
        return Viewport.height - (buttonSize / 2) - (buttonSize / 4) - 20;
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $images = $el.find('[data-gallery-item]');
    const $lines = $el.find('[data-gallery-line]');

    const $firstImage = $($images.get(0));
    const $restImages = $($images.nodes.slice(1));

    const BREAKPOINT_SP = 600;
    const BREAKPOINT_MP = 980;
    
    let areaHeight = 0;

    let tl = null;

    const init = () => {
        if ($images.length > 1) {
            onResize();
            Viewport.on('resize', onResize);
            Viewport.on('scroll', onScroll);
        }
        
        areaHeight = Viewport.height;
        createTimeline();
        updateProgress(true);
        $el.css({ opacity: 1 });
    };

    const destroy = () => {
        if ($images.length > 0) {
            Viewport.off('resize', onResize);
            Viewport.off('scroll', onScroll);
            destroyTimeline();
        }
    };

    const createTimeline = () => {
        if (tl) {
            destroyTimeline();
        }

        tl = gsap.timeline({});

        const firstImageTop = $firstImage.position().top;
        const firstImageLeft = $firstImage.position().left;
        const $firstImageContent = $firstImage.find('[data-gallery-item-content]');

        if ($firstImageContent.length > 0) {
            tl.from($firstImageContent.get(0), {
                duration: areaHeight/4,
                opacity: 0,
                y: -30,
                ease: 'power1.out'
            }, areaHeight/6);
        }
        
        $restImages.each((item, index) => {
            const $item = $(item);
            const $content = $item.find('[data-gallery-item-content]');
            const itemTop = $item.position().top;
            const itemHeight = $item.height();
            const targetXStart = firstImageLeft - $item.position().left;
            const targetYStart = firstImageTop - $item.position().top;

            switch (index) {
                case 0:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart - getOffsetType(2),
                        y: targetYStart - getOffsetType(1),
                        ease: 'power2.out'
                    }, 0);
                    break;
                case 1:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart + ($firstImage.width() - $item.width()) + getOffsetType(4),
                        y: targetYStart + ($firstImage.height() - $item.height()) + getOffsetType(3),
                        ease: 'power2.out'
                    }, 0);
                    break;
                case 2:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart - getOffsetType(1),
                        y: targetYStart - getOffsetType(1),
                        ease: 'power2.out'
                    }, 0);
                    break;
                case 3:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart + ($firstImage.width() - $item.width()),
                        y: targetYStart - getOffsetType(2),
                        ease: 'power2.out'
                    }, 0);
                    break;
                case 4:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart + ($firstImage.width() - $item.width()) + getOffsetType(4),
                        y: targetYStart + ($firstImage.height() - $item.height()) + getOffsetType(3),
                        ease: 'power2.out'
                    }, 0);
                    break;
                case 5:
                    tl.from(item, {
                        duration: itemTop + itemHeight,
                        x: targetXStart - getOffsetType(1),
                        y: targetYStart - getOffsetType(1),
                        ease: 'power2.out'
                    }, 0);
                    break;
            }
            
            if ($content.length > 0) {
                const contentTop = itemTop + $content.position().top;
                
                tl.from($content.get(0), {
                    duration: areaHeight / 2,
                    opacity: 0,
                    y: (index === 0 || index === 4) ? 30 : -30,
                    ease: 'power1.out'
                }, contentTop * 0.6);
            }
        });

        $lines.each((item, index) => {
            const $item = $(item);
            const itemTop = $item.position().top;
            
            gsap.set(item, { transformOrigin: 'top center' })

            tl.from(item, {
                duration: $item.height(),
                scaleY: 0,
                y: -areaHeight/10,
                ease: 'power1.out'
            }, itemTop);

        });

        tl.pause();
    };

    const destroyTimeline = () => {
        if (tl) {
            tl.kill();
            tl = null;
            
            gsap.set($restImages.nodes, { x: '', y: '' });
            gsap.set($el.find('[data-gallery-item-content]').nodes, { opacity: '', y: '' });
            gsap.set($lines.nodes, { transform: '', y: '' });
        }
    };

    const onScroll = e => {
        updateProgress();
    };

    const onResize = e => {
        if (Math.abs(Viewport.height-areaHeight) > 150) {
            areaHeight = Viewport.height;
            createTimeline();
            updateProgress(true);
        } else {
            updateProgress(false);
        }
    };

    const updateProgress = immediate => {
        const startPoint = $firstImage.offset().top - Math.min(areaHeight * 0.5, $firstImage.offset().top);
        const length = $el.height();

        if (Viewport.scrollTop < startPoint) {
            gsap.to(tl, { progress: 0, duration: immediate ? 0 : 1.4, ease: 'power2.out' })
        } else if (Viewport.scrollTop > (startPoint + length)) {
            gsap.to(tl, { progress: 1, duration: immediate ? 0 : 1.4, ease: 'power2.out' })
        } else {
            gsap.to(tl, { progress: (Viewport.scrollTop - startPoint) / length, duration: immediate ? 0 : 1.4, ease: 'power2.out' })
        }
    };

    const getOffsetType = num => {
        const currentSize = Viewport.breakpoint.size;

        switch (num) {
            case 1:
                if (currentSize >= BREAKPOINT_MP) {
                    return 45;
                } else if (currentSize >= BREAKPOINT_SP) {
                    return 30;
                } else {
                    return 20;
                }

            case 2:
                if (currentSize >= BREAKPOINT_MP) {
                    return 100;
                } else if (currentSize >= BREAKPOINT_SP) {
                    return 60;
                } else {
                    return 40;
                }

            case 3:
                if (currentSize >= BREAKPOINT_MP) {
                    return 135;
                } else if (currentSize >= BREAKPOINT_SP) {
                    return 80;
                } else {
                    return 50;
                }

            case 4:
                if (currentSize >= BREAKPOINT_MP) {
                    return 40;
                } else if (currentSize >= BREAKPOINT_SP) {
                    return 40;
                } else {
                    return 24;
                }

        }
    };

    return {
        init,
        destroy
    };
};
